import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IFooter } from '../../interfaces/menu.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menuFooter$: Observable<readonly IFooter[]>;

  constructor(private readonly sharedService: SharedService) {}

  ngOnInit(): void {
    this.menuFooter$ = this.sharedService.footer$;
  }
}
