import { AfterViewInit, Component } from '@angular/core';
import { TrendencySeoService } from '@trendency/utils';
import { CookieService } from './core/services/cookie.service';

@Component({
  selector: 'app-root',
  template: `
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-cookie></app-cookie>
  `,
})
export class AppComponent implements AfterViewInit {
  constructor(private readonly trendencySeoService: TrendencySeoService, private readonly cookieService: CookieService) {}

  ngAfterViewInit(): void {
    this.trendencySeoService.setMetaData({
      title: 'Nemzeti Ünnepek és Emléknapok',
      description: 'A Nemzeti Ünnepek és Emléknapok összefoglaló oldala',
      ogTitle: 'Nemzeti Ünnepek és Emléknapok',
      ogImage: '/assets/images/og_image.jpg',
      ogDescription: 'A Nemzeti Ünnepek és Emléknapok összefoglaló oldala',
    });

    this.cookieService.checkGoogleAnalyticsAccepted();
  }
}
