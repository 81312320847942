import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  PLATFORM_ID,
  SecurityContext,
  Type,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BrowserType, LogType } from '../utils.definitions';

declare var window, InstallTrigger, safari: any;

@Injectable()
export class TrendencyUtilsService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private sanitizer: DomSanitizer,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private snackBar: MatSnackBar
  ) {}

  // Ha nincs megadva type, visszaadja hogy böngésző oldalon vagyunk-e.
  // Ha van megadva type, akkor pedig hogy az adott böngésző típuson vagyunk-e
  public isBrowser(type?: BrowserType): boolean {
    const isBrowser = isPlatformBrowser(this.platformId);
    if (type && isBrowser) {
      switch (type) {
        // Opera 8.0+
        case 'opera':
          return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        case 'firefox':
          return typeof InstallTrigger !== 'undefined';
        // Safari 3.0+
        case 'safari':
          return (
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
              return p.toString() === '[object SafariRemoteNotification]';
            })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
          );
        // Internet Explorer 6-11
        case 'ie':
          return /*@cc_on!@*/ false || !!(<any>document).documentMode;
        // Edge 20+
        case 'edge':
          return !(/*@cc_on!@*/ (false || !!(<any>document).documentMode)) && !!window.StyleMedia;
        // Chrome 1 - 79
        case 'chrome':
          return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // Edge (based on chromium) detection
        case 'edge-chromium':
          return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf('Edg') !== -1;
      }
    }
    return isBrowser;
  }

  public log(message: string, type: LogType = 'info') {
    if (this.isBrowser()) {
      switch (type) {
        case 'info':
          console.log(`%c ${message}`, 'color: blue; background: #cce5ff; display: block; padding: 2px');
          break;
        case 'success':
          console.log(`%c ${message}`, 'color: green; background: #d4edda; display: block; padding: 2px');
          break;
        case 'warning':
          console.warn(message);
          break;
        case 'error':
          console.error(message);
          break;
        default:
          console.log(message);
          break;
      }
    } else {
      console.log(message);
    }
  }

  // átalakítja a YouTube videó linket beágyazható formátumra
  public convertYouTubeLinkToEmbed(link: string): string {
    if (link) {
      const embededLink = link.replace('watch?v=', 'embed/');
      const resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embededLink);
      return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, resourceUrl);
    }
    return '';
  }

  public createDynamicComponent(component: Type<any>, appRef?: ApplicationRef, parentElement?: Element): ComponentRef<any> {
    // 1. Create a component reference from the component
    const componentRef: ComponentRef<any> = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    if (appRef) {
      appRef.attachView(componentRef.hostView);
    }

    // 3. Get DOM element from component
    const domElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the parent element
    if (parentElement) {
      parentElement.appendChild(domElement);
    }

    return componentRef;
  }

  public showNotification(message: string, type: LogType = 'info', duration: number = 3000) {
    this.snackBar.open(message, 'x', {
      duration: duration,
      panelClass: ['notification', type],
      verticalPosition: 'top',
    });
  }

  public generateSlug(value: string): string {
    return value
      .normalize('NFD')
      .toLowerCase()
      .replace(/[\u0300-\u036f\?\$\.\,\\\/\|]/g, '')
      .replace(/@/gi, '-at-')
      .replace(/\s+/gi, '-');
  }

  public getGitVersion(): string {
    try {
      const version = require('../../../src/environments/git-version.js');
      return version.gitDescribe;
    } catch (e) {
      return '';
    }
  }
}
