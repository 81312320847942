import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { environment } from './environments/environment';

const { production, sentry, type } = environment;

if (environment.type === 'dev') {
  Sentry.init({
    environment: type,
    dsn: sentry?.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [...sentry?.tracingOrigins],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sentry?.sampleRate,
  });
}

if (production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
