import { Component, OnInit } from '@angular/core';
import { TrendencyUtilsService } from '@trendency/utils';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
  privacyAccepted: boolean;
  policyModalOpen: boolean;
  isBrowser: boolean;

  constructor(private readonly privacy: CookieService, private readonly trendUtilService: TrendencyUtilsService) {}

  ngOnInit(): void {
    this.isBrowser = this.trendUtilService.isBrowser();
    this.privacyAccepted = this.privacy.isPolicyAccepted();
  }

  onAcceptPolicyButtonClick(accept: boolean): void {
    this.privacyAccepted = true;
    this.privacy.acceptPolicy(accept);
  }
}
