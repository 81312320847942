import { Injectable } from '@angular/core';
import { TrendencyReqService } from '@trendency/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly reqService: TrendencyReqService) {}

  getInit$(): Observable<any> {
    return this.reqService.get(`init`);
  }

  getMenu$(): Observable<any> {
    return this.reqService.get(`menu/header-footer`);
  }

  getTimeline$(): Observable<any> {
    return this.reqService.get(`timeline`);
  }
}
