<section class="cookie" [ngClass]="{ shown: isBrowser && !privacyAccepted }">
  <div class="wrapper">
    <div class="content">
      <h3>Ez a honlap sütiket használ.</h3>
      A sütik elfogadásával kényelmesebbé teheted a böngészést. A honlap további használatával hozzájárulsz a sütik
      használatához.
      <a [routerLink]="['/', 'sutik']">További információt itt találsz.</a>
    </div>
    <div class="buttons">
      <button type="button" class="accept" (click)="onAcceptPolicyButtonClick(true)">elfogadom</button>
      <button type="button" class="decline" (click)="onAcceptPolicyButtonClick(false)">elutasítom</button>
    </div>
  </div>
</section>