import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFooter, IHeader } from 'src/app/core/interfaces/menu.interface';
import { ITimeline } from '../components/timeline/interfaces/timeline.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  header$: BehaviorSubject<readonly IHeader[]> = new BehaviorSubject(undefined);
  footer$: BehaviorSubject<readonly IFooter[]> = new BehaviorSubject(undefined);
  timeline$: BehaviorSubject<readonly ITimeline[]> = new BehaviorSubject(undefined);
}
