import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IHeader } from '../../interfaces/menu.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHeader$: Observable<readonly IHeader[]>;

  hamburgerOpen = false;
  mouseOver: number;
  defaultColor = '#000000';

  constructor(private readonly sharedService: SharedService, private readonly router: Router) {}

  toggleHamburger(): void {
    this.hamburgerOpen = !this.hamburgerOpen;
  }

  ngOnInit(): void {
    this.menuHeader$ = this.sharedService.header$;

    this.router.events.subscribe((evt: Event) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.hamburgerOpen = false;
    });
  }
}
