import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { forkJoin, Observable, throwError } from 'rxjs';
import { ApiService } from '../services/api.service';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({ providedIn: 'root' })
export class InitResolver implements Resolve<any> {
  constructor(private readonly apiService: ApiService, private readonly sharedService: SharedService) {}

  resolve(): Observable<any> | Promise<any> | any {
    const timeline$ = this.apiService.getTimeline$();
    const menu$ = this.apiService.getMenu$();

    return forkJoin([
      timeline$.pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }),
        map((data: any) => data.data),
        tap((data: any) => {
          this.sharedService.timeline$.next(data.timeline);
        })
      ),
      menu$.pipe(
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        }),
        map((data: any) => data.data),
        tap((data: any) => {
          this.sharedService.header$.next(data.menu.header);
          this.sharedService.footer$.next(data.menu.footer);
        })
      ),
    ]);
  }
}
