import { IEnvironment } from './environment.definitions';

// Éles környezet

export const environment: IEnvironment = {
  production: true,
  type: 'prod',
  apiUrl: 'https://nemzetiunnepek.hu/publicapi/v1/nemzetiunnepek',
  googleAnalyticsCode: '',
  translation: {
    locales: ['hu'],
    prefix: 'ROUTES.',
    localesFolder: {
      client: '/assets/locales',
      server: '/../browser/assets/locales',
    },
  },
  redis: {
    redisIp: '10.30.1.5',
    redisPort: 6379,
    redisPrefix: 'angular_starter_prod',
    redisRequestLimit: 500,
  },
};
