import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TrendencyTranslationsRootModule } from '@trendency/translations';
import { TrendencyHttpInterceptor, TrendencyHttpModule } from '@trendency/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import * as Sentry from '@sentry/angular';

const SENTRY_PROVIDERS: ReadonlyArray<any> = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    }),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    HttpClientModule,
    TrendencyHttpModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
    TrendencyTranslationsRootModule.forRoot(appRoutes),
    CoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrendencyHttpInterceptor,
      multi: true,
    },
    ...SENTRY_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // tslint:disable-next-line: unnecessary-constructor no-empty
  constructor(_trace: Sentry.TraceService) {}
}
