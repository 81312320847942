import { ModuleWithProviders, NgModule, Optional, PLATFORM_ID, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { TransferState } from '@angular/platform-browser';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { Routes } from '@angular/router';

import { translateLoaderFactory, TrendencyMissingTranslationHandler, TrendencyMissingTranslationService } from './translate-loaders';
import { localizeLoaderFactory, setDefaultLanguage } from './localize-loaders';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [PLATFORM_ID, TransferState, HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TrendencyMissingTranslationHandler,
        deps: [TrendencyMissingTranslationService],
      },
      useDefaultLang: false,
    }),
    LocalizeRouterModule,
  ],
  exports: [TranslateModule, LocalizeRouterModule],
})
export class TrendencyTranslationsRootModule {
  constructor(@Optional() @SkipSelf() translationsRootModule: TrendencyTranslationsRootModule) {
    if (translationsRootModule) {
      throw new Error('A TrendencyTranslationsRootModule-t csak az AppModule-ba importáld!');
    }
  }

  static forRoot(routes: Routes): ModuleWithProviders<TrendencyTranslationsRootModule> {
    return {
      ngModule: TrendencyTranslationsRootModule,
      providers: [
        LocalizeRouterModule.forRoot(routes, {
          parser: {
            provide: LocalizeParser,
            useFactory: localizeLoaderFactory,
            deps: [TranslateService, Location, LocalizeRouterSettings],
          },
          alwaysSetPrefix: false,
          defaultLangFunction: setDefaultLanguage,
        }).providers,
      ],
    };
  }
}
