import { ModuleWithProviders, NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { Routes } from '@angular/router';

import { TrendencyMissingTranslationHandler, TrendencyMissingTranslationService } from './translate-loaders';

@NgModule({
  imports: [
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TrendencyMissingTranslationHandler,
        deps: [TrendencyMissingTranslationService],
      },
      useDefaultLang: false,
    }),
    LocalizeRouterModule,
  ],
  exports: [TranslateModule, LocalizeRouterModule],
})
export class TrendencyTranslationsChildModule {
  static forChild(routes: Routes): ModuleWithProviders<TrendencyTranslationsChildModule> {
    return {
      ngModule: TrendencyTranslationsChildModule,
      providers: [LocalizeRouterModule.forChild(routes).providers],
    };
  }
}
