<section class="header">
  <div class="wrapper">
    <div class="header-left">
      <nav role="navigation" aria-label="Fő menü">
        <a [href]="'https://www.facebook.com/nemzetiunnepek'" target="_blank" class="social-icon fb"></a>
        <a [href]="'https://www.instagram.com/nemzetiunnepekesemleknapok/'" target="_blank" class="social-icon insta"></a>
      </nav>
    </div>
    <div class="hamburger-container">
      <button class="hamburger-button" (click)="toggleHamburger()">
        <span class="menu-text">Menü</span>
        <span class="hamburger"></span>
      </button>
    </div>
  </div>
  <div class="dropdown-menu" [ngClass]="{ open: hamburgerOpen }">
    <nav class="hamburger-nav">
      <a [routerLink]="['/']" class="hamburger-item">Főoldal</a>
      <ng-container *ngFor="let menuItem of menuHeader$ | async; let i = index">
        <a
          (mouseenter)="mouseOver = i"
          (mouseleave)="mouseOver = null"
          [routerLink]="['/', 'nemzeti-unnep', menuItem.slug]"
          class="hamburger-item"
          [ngStyle]="{ color: i === mouseOver ? menuItem.color : defaultColor }"
        >
          {{ menuItem.name }}
        </a>
      </ng-container>
    </nav>
  </div>
</section>
