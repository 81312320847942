import { Routes } from '@angular/router';
import { Error404Component } from './core/error-pages/404/404.component';
import { InitResolver } from './core/resolvers/init.resolver';

/* tslint:disable */
export const appRoutes: Routes = [
  {
    path: '',
    resolve: { InitResolver },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'nemzeti-unnep/:nationalHolidaySlug',
        loadChildren: () =>
          import('./modules/national-holiday-detail/national-holiday-detail.module').then((m) => m.NationalHolidayDetailModule),
      },
      {
        path: '404',
        component: Error404Component,
      },
      {
        path: ':staticPageSlug',
        loadChildren: () => import('./modules/static-page/static-page.module').then((m) => m.StaticPageModule),
      },
      {
        path: '**',
        redirectTo: '404',
      },
    ],
  },
];
