import { NgModule } from '@angular/core';

import { TrendencyReqService } from './services/req.service';
import { TrendencyUtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [TrendencyUtilsModule],
  exports: [],
  declarations: [],
  providers: [TrendencyReqService],
})
export class TrendencyHttpModule {}
