import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TrendencyUtilsService } from '@trendency/utils';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(
    private readonly trendUtilService: TrendencyUtilsService,
    private readonly router: Router
    ) { }

  acceptPolicy(accept: boolean): void {
    if (!this.trendUtilService.isBrowser()) {
      return;
    }

    const now = new Date();
    // tslint:disable-next-line: no-magic-numbers
    const exp = new Date(now.getTime() + 31 * 24 * 60 * 60 * 1000);
    document.cookie = `privacy-policy=1; path=/; expires=${exp.toUTCString()}`;

    if (accept && environment.type === 'prod') {
      document.cookie = `gaAccept=true; path=/; expires=${exp.toUTCString()}`;
      this.initGoogleAnalytics();
    } else {
      document.cookie = `gaAccept=false; path=/; expires=${exp.toUTCString()}`;
    }
  }

  checkGoogleAnalyticsAccepted(): void {
    const GA = this.isGAaccepted();

    if (GA) {
      this.initGoogleAnalytics();
    }
  }

  private initGoogleAnalytics(): void {
    if (!environment.googleAnalyticsCode && document.getElementById('ga-script')) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'ga-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsCode}`;
    script.onload = () => {
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsCode}');
      `;
      document.head.appendChild(gaScript);
      this.trackPageViews();
    };
    document.head.appendChild(script);
  }

  private trackPageViews(): void {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.gtag('event', 'page_view', {
          page_location: window.location.href,
          page_path: event.urlAfterRedirects,
        });
      });
  }

  private gtag(...args: any): void {
    (window as any).dataLayer.push(args);
  }

  isPolicyAccepted(): boolean {
    if (!this.trendUtilService.isBrowser()) {
      return;
    }

    const value = `; ${document.cookie}`;
    return value.indexOf('; privacy-policy=') > -1;
  }

  isGAaccepted(): boolean {
    if (!this.trendUtilService.isBrowser()) {
      return;
    }

    const value = `; ${document.cookie}`;
    return value.indexOf('; gaAccept=') > -1;
  }
}
